import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';

import { concatMap } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { UserService } from '@shared/services/user.service';
import { AuthService } from '@core/auth/auth.service';
import { environment } from 'src/environments/environment';

@Injectable()
export class OrganizationInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService, private userService: UserService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (
      request.headers.get(environment.avoidOrgId) ||
      request.url.includes('i18n') ||
      request.url.includes('password-change') ||
      request.url.includes('change_password') ||
      !request ||
      !request.url
    ) {
      return next.handle(request.clone());
    }
    return this.authService.isAuthenticated$.pipe(
      concatMap(loggedIn => {
        if (!loggedIn) {
          return next.handle(request);
        } else {
          return this.manageOrganization(request, next);
        }
      })
    );
  }

  manageOrganization(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return this.userService.getUserOrganization().pipe(
      concatMap(organizationId => {
        let cloneReq = request.clone({
          headers: request.headers.set('OrganizationId', organizationId),
        });
        if (request.url.search('{organizationId}') !== -1) {
          cloneReq = cloneReq.clone({
            url: cloneReq.url.replace('{organizationId}', organizationId),
          });
        }
        return next.handle(cloneReq);
      })
    );
  }
}
