import { IAdvancedRule } from '@advanced-rules/advanced-rule.model';
import { ConsentCampaignStatus } from './consent-policy/consent-campaign-status';
import { IConsentSurvey } from './consent-policy/consent-survey.model';
import { ProtectionLevel } from '@core/models/protection-level';
import { IExpirationTime } from '@shared/features/custom-forms/expirationTime';

export interface IConsentReward {
  description: string;
  quantity: string;
}

export enum ContactType {
  Email = 'Email',
  Trigger = 'Trigger',
}

export enum CampaignType {
  Dataset = 'Dataset',
  Survey = 'Survey',
}

export interface ICampaignWebhook {
  campaignId: string;
  webhookId: string;
  name: string;
}

export interface IConsentDataSet {
  consentedDataOwners?: number;
  datasetId: string;
  name?: string;
  columnNameEmailOwner: string;
  payload: { rules: IAdvancedRule[] };
  rewards: IConsentReward[];
  showMetaData?: boolean;
  tokenType?: any;
  tokenQuantity?: any;
}

export enum ImageFitType {
  FILL = 'fill',
  FIT = 'fit',
}

export interface ICampaignStyles {
  color?: string;
  backgroundColor: string;
  backgroundImage: File | string;
  backgroundImageMode: ImageFitType;
  backgroundCoverColor?: string;
  backgroundCoverImage?: File | string;
  backgroundCoverImageMode?: ImageFitType;
  strokeColor: string;
  fontPrimaryColor: string;
  fontSecondaryColor: string;
  actionFillColor: string;
  actionFontColor: string;
  font: null;
}

export interface IConsentCampaign extends ICampaignStyles {
  id?: string;
  title: string;
  image?: File;
  imageFit: ImageFitType;
  imageName?: string;
  reviewer?: string;
  protectionLevel?: ProtectionLevel;
  dataUsageDescription?: string;
  dataUsageDuration?: IExpirationTime;
  datasets?: IConsentDataSet[];
  status?: ConsentCampaignStatus;
  organizationId?: string;
  survey?: IConsentSurvey;
  surveyContent?: string;
  surveyVersion?: string;
  contactType: ContactType;
  campaignType: CampaignType;
  campaignWebhooks?: ICampaignWebhook[];
  hideTransparencyText: boolean;
  daysBeforeExpire?: number;
  originalCampaignId?: string;
}
