import { Injectable, Injector } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';

import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { AuthService } from '@core/auth/auth.service';
import { environment } from 'src/environments/environment';

@Injectable()
export class AuthHttpInterceptor implements HttpInterceptor {
  constructor(private injector: Injector) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.headers.get(environment.avoidAuth) || req.url.includes('i18n') || req.url.includes('password-change') || !req || !req.url) {
      return next.handle(req.clone());
    }
    const authService = this.injector.get(AuthService);
    return authService.getTokenSilently().pipe(
      switchMap(({ __raw }) => {
        // Clone the request and attach the bearer token
        const clone = req.clone({
          headers: req.headers.set('Authorization', `Bearer ${__raw}`),
        });

        return next.handle(clone);
      })
    );
  }
}
