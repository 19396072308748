import { IAdvancedRule } from '@advanced-rules/advanced-rule.model';
import { IConsentDataSet, IConsentCampaign, CampaignType, ICampaignStyles } from '@consent/consent-campaign.model';
import { IConsentSurvey } from './consent-survey.model';
import { ICampaignMobileData } from '@consent/consent-policy-mobile/consent-policy-mobile-campaign.model';
import { ICampaignSurveyNonAuth } from 'src/app/landings/campaign-survey/campaign-survey-non-auth.model';

export const mapConsentCampaignDatasetPayload = (rules: IAdvancedRule[]) => {
  return rules.map(rule => {
    const { canReceive, conditions, selectedRecipients } = rule;
    return {
      attributes: (canReceive || []).map(item => {
        return { path: item, fee: 0 };
      }),
      conditions: (conditions || []).map(condition => {
        return {
          columnName: condition.attribute,
          operator: condition.operation,
          value: condition.value,
        };
      }),
      recipients: selectedRecipients.map(selectedRecipient => {
        return {
          recipient: selectedRecipient,
        };
      }),
    };
  });
};

export const mapConsentCampaignDatasets = (dataset: IConsentDataSet) => {
  const { datasetId, columnNameEmailOwner, payload, rewards, showMetaData } = dataset;
  return {
    datasetId,
    columnNameEmailOwner,
    showMetaData,
    rewards,
    payloads: mapConsentCampaignDatasetPayload(payload.rules),
  };
};

export const mapConsentCampaign = (consentCampaign: IConsentCampaign): any => {
  const backendModel = {
    title: consentCampaign.title,
    reviewer: consentCampaign.reviewer,
    protectionLevel: consentCampaign.protectionLevel,
    dataUsageDescription: consentCampaign.dataUsageDescription,
    dataUsageDuration: consentCampaign.dataUsageDuration.periodUnit,
    dataUsageDurationUnit: consentCampaign.dataUsageDuration.periodTime,
    datasets: [],
    campaignType: consentCampaign.campaignType,
    contactType: consentCampaign.contactType,
    surveyContent: consentCampaign.survey?.surveyContent,
    imageFit: consentCampaign.imageFit,
    hideTransparencyText: consentCampaign.hideTransparencyText,
    daysBeforeExpire: consentCampaign.daysBeforeExpire,
    originalCampaignId: consentCampaign.originalCampaignId,
    /** CUSTOM STYLES */
    backgroundColor: consentCampaign.backgroundColor,
    backgroundImageMode: consentCampaign.backgroundImageMode,
    backgroundCoverColor: consentCampaign.backgroundCoverColor,
    backgroundCoverImageMode: consentCampaign.backgroundCoverImageMode,
    strokeColor: consentCampaign.strokeColor,
    fontPrimaryColor: consentCampaign.fontPrimaryColor,
    fontSecondaryColor: consentCampaign.fontSecondaryColor,
    actionFillColor: consentCampaign.actionFillColor,
    actionFontColor: consentCampaign.actionFontColor,
    font: consentCampaign.font,
  };
  if (consentCampaign.campaignType === CampaignType.Dataset) {
    const { datasets } = consentCampaign;
    datasets.forEach(dataset => {
      backendModel.datasets.push(mapConsentCampaignDatasets(dataset));
    });
  } else {
    backendModel.datasets.push(mapSurveyToConsentCampaign(consentCampaign.survey));
  }
  return consentCampaign.id ? { ...backendModel, id: consentCampaign.id } : backendModel;
};

export const mapConsentUICampaignDatasetPayload = (rules: any[]) => {
  return rules.map(rule => {
    const { attributes, conditions, recipients } = rule;
    return {
      canReceive: attributes.map(attribute => {
        return attribute.path;
      }),
      conditions: conditions.map(condition => {
        return {
          attribute: condition.columnName,
          operation: condition.operator,
          value: condition.value,
        };
      }),
      selectedRecipients: recipients.map(recipient => {
        return recipient.recipient;
      }),
    };
  });
};

export const mapConsentUICampaignDatasets = (dataset: any) => {
  const { datasetId, consentedDataOwners, columnNameEmailOwner, showMetaData, name, payloads, rewards, tokenType, tokenQuantity } = dataset;
  return {
    datasetId,
    columnNameEmailOwner,
    consentedDataOwners,
    showMetaData,
    name,
    rewards,
    tokenType,
    tokenQuantity,
    payload: { rules: mapConsentUICampaignDatasetPayload(payloads) },
  };
};

export const mapConsentUICampaign = (consentCampaign: any): IConsentCampaign => {
  const UIModel = {
    campaignType: consentCampaign.campaignType,
    contactType: consentCampaign.contactType,
    color: consentCampaign.color,
    id: consentCampaign.id,
    image: consentCampaign.image,
    imageName: consentCampaign.imageName,
    imageFit: consentCampaign.imageFit,
    title: consentCampaign.title,
    reviewer: consentCampaign.reviewer,
    protectionLevel: consentCampaign.protectionLevel,
    dataUsageDescription: consentCampaign.dataUsageDescription,
    status: consentCampaign.status,
    surveyFileName: consentCampaign.surveyFileName,
    surveyContent: consentCampaign.surveyContent,
    dataUsageDuration: {
      periodUnit: consentCampaign.dataUsageDuration,
      periodTime: consentCampaign.dataUsageDurationUnit,
    },
    datasets: [],
    organizationId: consentCampaign.organizationId,
    campaignWebhooks: consentCampaign.campaignWebhooks,
    hideTransparencyText: consentCampaign.hideTransparencyText,
    expireTime: consentCampaign.expireTime,
    daysBeforeExpire: consentCampaign.daysBeforeExpire,
    originalCampaignId: consentCampaign.originalCampaignId,
    /** STYLES */
    backgroundColor: consentCampaign.backgroundColor,
    backgroundImage: consentCampaign.backgroundImage,
    backgroundImageMode: consentCampaign.backgroundImageMode,
    backgroundCoverColor: consentCampaign.backgroundCoverColor,
    backgroundCoverImage: consentCampaign.backgroundCoverImage,
    backgroundCoverImageMode: consentCampaign.backgroundCoverImageMode,
    strokeColor: consentCampaign.strokeColor,
    fontPrimaryColor: consentCampaign.fontPrimaryColor,
    fontSecondaryColor: consentCampaign.fontSecondaryColor,
    actionFillColor: consentCampaign.actionFillColor,
    actionFontColor: consentCampaign.actionFontColor,
    font: consentCampaign.font,
  };
  const { datasets } = consentCampaign;
  datasets.forEach(dataset => {
    UIModel.datasets.push(mapConsentUICampaignDatasets(dataset));
  });
  return UIModel;
};

export const mapSurveyToConsentCampaign = (survey: IConsentSurvey) => {
  const { rewards, selectedRecipients } = survey;
  const item: IConsentDataSet = {
    datasetId: null,
    columnNameEmailOwner: null,
    showMetaData: false,
    rewards,
    payload: {
      rules: [
        {
          selectedRecipients,
          canReceive: null,
        },
      ],
    },
  };
  return mapConsentCampaignDatasets(item);
};

type Campaign = IConsentCampaign | ICampaignMobileData | ICampaignSurveyNonAuth;

export const defaultUIStyles: ICampaignStyles = {
  backgroundColor: '#ffffff',
  backgroundImage: null,
  backgroundImageMode: null,
  backgroundCoverColor: null,
  backgroundCoverImage: null,
  backgroundCoverImageMode: null,
  strokeColor: '#E8E8EB',
  fontPrimaryColor: '#171a37',
  fontSecondaryColor: '#747687',
  actionFillColor: '#850599',
  actionFontColor: '#ffffff',
  font: null,
};

export const getCampaignStyles = (campaign: Campaign): ICampaignStyles => {
  if (!campaign) return null;
  const {
    backgroundColor,
    backgroundImage,
    backgroundImageMode,
    backgroundCoverColor,
    backgroundCoverImage,
    backgroundCoverImageMode,
    color,
    strokeColor,
    fontPrimaryColor,
    fontSecondaryColor,
    actionFillColor,
    actionFontColor,
    font,
  } = campaign;
  return {
    backgroundColor,
    backgroundImage,
    backgroundImageMode,
    backgroundCoverColor,
    backgroundCoverImage,
    backgroundCoverImageMode,
    color,
    strokeColor,
    fontPrimaryColor,
    fontSecondaryColor,
    actionFillColor,
    actionFontColor,
    font,
  };
};

export const getRegularStyles = (campaign: Campaign): ICampaignStyles => {
  const styles = getCampaignStyles(campaign);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { backgroundCoverColor, backgroundCoverImage, backgroundCoverImageMode, ...rest } = styles;
  return {
    ...rest,
  };
};

export const getAltStyles = (campaign: Campaign): ICampaignStyles => {
  const styles = getCampaignStyles(campaign);
  const { backgroundCoverColor, backgroundCoverImage, backgroundCoverImageMode, ...rest } = styles;
  return {
    ...rest,
    backgroundImage: backgroundCoverImage,
    backgroundColor: backgroundCoverColor,
    backgroundImageMode: backgroundCoverImageMode,
  };
};

export const getStyles = (campaign: Campaign, displaySurvey: boolean): ICampaignStyles => {
  const styles = getCampaignStyles(campaign);
  if (emptyStyles(styles)) {
    const { actionFillColor, color } = styles;
    return { ...defaultUIStyles, actionFillColor: color || actionFillColor };
  }
  const { backgroundCoverColor, backgroundCoverImage, backgroundCoverImageMode } = styles;
  const differenceCoverSet = backgroundCoverImage || backgroundCoverColor || backgroundCoverImageMode;
  // no different cover properties have been provided
  if (!differenceCoverSet) {
    return getRegularStyles(campaign);
  }
  return displaySurvey ? getRegularStyles(campaign) : getAltStyles(campaign);
};

const emptyStyles = (styles: ICampaignStyles): boolean => {
  const { color, ...rest } = styles;
  const empty = Object.keys(rest).every(key => {
    return styles[key] === '';
  });
  return empty;
};
